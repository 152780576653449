<template>
  <div class="top-header printPdf">
    <div class="container-fluid">
      <div class="row">
        <div class="col col-sm-3 col-md-2 col-lg-2 col-xxl-2 call-us-container">
          <div class="header-contact">
            <div class="call-us">
                <NuxtLinkLocale :to="{ path: '/' }" aria-label="Logo">
                  <img class="logo" height="120px" width="auto" :class="{ small: y > 100 }" alt="cld logo"
                    src="~~/assets/cld-logo.svg" />
                </NuxtLinkLocale>
            </div>
          </div>
        </div>
        <div class="col col-sm-3 col-md-4 col-lg-7 col-xxl-6 top-toggle-fix">
          <div class="main-menu">
            <div class="menu-left">
              <Nav />
            </div>
          </div>
        </div>

        <div class="col col-sm-6 col-md-6 col-lg-3 col-xxl-4 account-log icon-nav">
          <ul class="header-dropdown account-log-sub ">
            <li>
              <div class="main-menu">
                <div class="menu-right">
                  <HeaderWidgets />
                </div>
              </div>
            </li>
          </ul>


        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Nav from "../widgets/navbar.vue";
import HeaderWidgets from "../widgets/header-widgets.vue";

import { useWindowScroll } from '@vueuse/core'

const router = useRouter();


const { y } = useWindowScroll()
// const { locale } = useI18n();
// const utilityStore = useUtilityStore();
// const logout = () => {
//   UserAuth.Logout();
//   isLogin.value = false;
//   router.push({
//     path: "/account/login",
//   });
// };
// const languageSelection = (language) => {
//   utilityStore.setLanguage(language);
//   locale.value = language.split("_")[0];
// };


</script>
<style>
.call-us-container {

  /* display: flex;
  align-content: center;
  justify-content: flex-end; */

}

.top-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 70px;
  cursor: pointer;
}

.flagicon {
  cursor: pointer;
  margin: 1px;
  height: 23px;
  width: 23px;
}

.logo {
  height: 80px;
  transform: rotate(5deg);
  /* cursor: pointer;
  width: auto;
  position: fixed;
  top: 10px;
  left: 20px;
  z-index: 9999;
  transform: scale(1);
  transition: transform 0.2s ease-in-out; */
}

.logo.small {
  transform: scale(0.5);
  top: 0;
  left: 0;
  animation: logo-shrink 0.2s ease-in-out;
}


@keyframes logo-shrink {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0.5);
  }
}

.main-menu .menu-right {
  display: flex;
  float: right;
  padding: 5px 5px;
  /* background-color: #e3e3e3; */
  border-radius: 2px;
}

.call-us {
  font-size: 12px;
  line-height: 5px;
  width: fit-content;
}

.account-log {
  /* width: fit-content;
  top: 0; */
  /* left: 250px; */
}

/* .account-log-sub {
  width: fit-content;
} */

.fa-user {
  font-size: 20px;
}

@media (max-width:990px)and (min-width:200px) {
  .call-us-container {
    display: none;
  }

}
</style>
